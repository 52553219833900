import { NumberString } from '@models/common';

function numberStringDtoMapper(val: NumberString | number, defaultValue: number): number;
function numberStringDtoMapper(val: NumberString | number): number | undefined;
function numberStringDtoMapper(val?: NumberString | number, defaultValue?: number) {
  if (val === undefined || val === null) {
    return defaultValue;
  }
  const parsed = Number(val);

  return Number.isNaN(parsed) ? defaultValue : parsed;
}

export { numberStringDtoMapper };
