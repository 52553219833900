import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { registerLinksHandler } from '~/services/linksHandler';
import { organizationSelector } from '@store/organization/selectors';
import * as S from './FooterDisclaimer.styled';

type Props = {
  className?: string;
  hideFeesApplyLink?: boolean;
};

export const FooterDisclaimer: FC<Props> = (props) => {
  const organization = useSelector(organizationSelector);
  const [containerElement, setContainerElement] = useState<HTMLDivElement | null>(null);

  const fullTermsLink = useMemo(() => organization?.organizationInfo?.full_disclosure_link, [organization]);

  const updateContainerElement = useCallback((el: HTMLDivElement | null) => {
    if (el) {
      setContainerElement(el);
    }
  }, []);

  useEffect(() => {
    if (containerElement) {
      const unsubscribe = registerLinksHandler(containerElement);
      return () => {
        unsubscribe();
      };
    }
  }, [containerElement]);

  if (!organization?.organizationInfo?.email_additional_footer) {
    return null;
  }

  return (
    <S.Container className={props.className} ref={updateContainerElement}>
      <S.Text dangerouslySetInnerHTML={{ __html: organization.organizationInfo.email_additional_footer }} />
      {!props.hideFeesApplyLink && <S.FeesLink>Fees may apply.</S.FeesLink>}{' '}
      {fullTermsLink && (
        <S.DisclosuresLink href={fullTermsLink} target="_blank">
          Read full disclosure.
        </S.DisclosuresLink>
      )}
    </S.Container>
  );
};
