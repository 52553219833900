import styled from 'styled-components';
import { Button } from '~/ui/kit/atoms/Button';
import { fontSize } from '~/ui/kit/constants/fonts';

export const Container = styled.div.attrs({ $fs: 'xxs' })`
  --link_color: inherit;
  ${fontSize};

  a,
  a:hover,
  a:active {
    color: var(--link_color);
  }
`;

export const Text = styled.span`
  & > div {
    display: inline;
  }
`;

export const FeesLink = styled(Button).attrs({
  link: '/faq',
  type: 'link',
})`
  &.ant-btn {
    font-size: inherit;

    &,
    &:hover,
    &:focus,
    &:active {
      color: var(--link_color);
    }
  }
`;

export const DisclosuresLink = styled.a`
  &,
  &:hover,
  &:focus,
  &:active {
    color: var(--link_color);
  }

  &:focus-visible {
    outline-color: var(--eko-button-outline-color);
  }
`;
