import { useCallback, useEffect, useRef } from 'react';

const appRootElement = document.querySelector<HTMLElement>('#app') || undefined;

type Position = 'center' | 'bottom' | 'top';

type ScrollToParams = {
  elementToScroll?: HTMLElement | null;
  position?: Position;
  additionOffset?: number;
};

const getOffsetTop = (element?: HTMLElement): number => {
  if (!element) return 0;

  /** Calculate transform translateY value */
  const transformXValue = parseFloat((getComputedStyle(element).transform || '').split(',')[5]);

  return (
    /** Calculate all parents' offsetTop recursively from bottom to top */
    getOffsetTop(element.offsetParent as HTMLElement) +
    element.offsetTop +
    /** Add translateY if exists */
    (Number.isNaN(transformXValue) ? 0 : transformXValue)
  );
};

export const useScrollViewport = (containerToScroll = appRootElement, shouldCleanUpScroll: boolean = true) => {
  const containerElementRef = useRef<HTMLElement>();

  /* Store container element as ref value */
  useEffect(() => {
    if (containerToScroll) {
      containerElementRef.current = containerToScroll;
    }
  }, [containerToScroll]);

  const scrollContainerTo = useCallback((topOffset: number = 0) => {
    if (containerElementRef.current) {
      containerElementRef.current?.scroll({ top: topOffset, behavior: 'smooth' });
    }
  }, []);

  /** Clean up container's scroll in case of mount */
  useEffect(() => {
    scrollContainerTo();

    /** Clean up container's scroll by default in case of unmount */
    return () => {
      if (shouldCleanUpScroll) {
        scrollContainerTo();
      }
    };
  }, [scrollContainerTo, shouldCleanUpScroll]);

  return useCallback(
    ({ elementToScroll, position = 'bottom', additionOffset = 0 }: ScrollToParams = {}) => {
      if (!elementToScroll || !containerElementRef.current) {
        return;
      }

      const containerHeight = containerElementRef.current?.offsetHeight || 0;
      const elementHeight = elementToScroll.offsetHeight || 0;

      const elementTopPosition = getOffsetTop(elementToScroll as HTMLElement);
      const containerTopPosition = getOffsetTop(containerElementRef.current as HTMLElement);
      const elementTopPositionFromContainersTopPosition = elementTopPosition - containerTopPosition;

      if (position === 'bottom') {
        scrollContainerTo(
          elementTopPositionFromContainersTopPosition - containerHeight + elementHeight + additionOffset,
        );
      }

      if (position === 'top') {
        scrollContainerTo(elementTopPositionFromContainersTopPosition + additionOffset);
      }

      if (position === 'center') {
        scrollContainerTo(
          containerHeight / 2 +
            (elementTopPositionFromContainersTopPosition - containerHeight) +
            elementHeight / 2 +
            additionOffset,
        );
      }
    },
    [scrollContainerTo],
  );
};
